import React, { useState } from 'react';
import ProfileImage from '../../images/pixArt.png';
import DarkProf from '../../images/darkPixArt.png';

function About({ darkMode, toggleDarkMode }) {
  const [currentImage, setCurrentImage] = useState(ProfileImage);

  const handleDarkModeToggle = () => {
    setCurrentImage(currentImage === ProfileImage ? DarkProf : ProfileImage);
    toggleDarkMode(); // Toggle dark mode in the parent component
  };

  return (
    <div>

      <div className={`${darkMode ? 'bg-TheArker' : 'bg-lightNavbar'} h-1 absolute left-0 right-0 top-100`}></div>
      <div className="bg-Sunburst h-1 absolute left-0 right-0 top-100"></div>


      <div className={`${darkMode ? 'bg-TheArker' : 'bg-lightNavbar'} mx-auto py-8 px-4 md:px-8 h-screen overflow-hidden ${darkMode ? 'text-white' : 'text-black'}`}>
        <div className="flex items-center mb-8">
          <img
            src={`${darkMode ? ProfileImage : DarkProf}`}
            alt="Your Name"
            className="w-64 h-64 rounded-full mr-4"
          />
          <div>
            <h1 className={`text-2xl font-bold ${darkMode ? 'text-Sunburst' : 'text-black'}`}>
              Agrim Shiwakoti
            </h1>
            <p className={darkMode ? 'text-white' : 'text-RusViolet'}>Software Developer</p>
          </div>
        </div>
        <p className={`mb-8 ${darkMode ? 'text-white' : 'text-DimGray'}`}>
          I am passionate about integrating technology into everyday life to push us forward in the evolution of human experience. With a background in Software Engineering, I've developed a versatile skill set that includes technologies such as C#, JavaScript, Java, Node.js, React, Angular, MEAN/MERN stack, .NET, Python, mySQL, and NoSQL databases like PrismaDB.
        </p>
        <p className={`mb-8 ${darkMode ? 'text-white' : 'text-DimGray'}`}>
          My areas of expertise span programming, gaming, speculation, and philosophical debates. At the end of the day, I believe that everyone is striving to fulfill their aspirations, and it's important to maintain perspective and not get overwhelmed by the details. Life is a journey of unraveling and rearranging understanding, so let's embrace the process with a sense of calm.
        </p>
        <p className={`mb-8 ${darkMode ? 'text-white' : 'text-DimGray'}`}>
          Feel free to reach out to me at <a href="mailto:agrimsiwakoti5@gmail.com" className={darkMode ? 'text-Sunburst underline' : 'text-SBLight underline'}>
            agrimsiwakoti5@gmail.com
          </a> if you'd like to connect or collaborate.
        </p>
      </div>
    </div>
  );
}

export default About;
