import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons'; // Icons


const Navbar = ({darkMode, toggleDarkMode}) => {
  
  const darkModeHandler = () => {
    console.log('Toggle Dark Mode clicked');
    toggleDarkMode();
  };
  
  return (
    <nav className={`${darkMode ? 'bg-darkNavbar' : 'bg-lightNavbar'} p-4`}>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <Link
            to="/"
            className={`p-2 rounded-md transition-colors duration-300 ${
              darkMode ? 'text-white' : 'text-black'
            }`} // Text color changes based on darkMode
          >
            <div className={`text-${darkMode ? 'white' : 'DimGray'} text-lg font-semibold hover:text-Sunburst`}>
              Home
            </div>
          </Link>
          <Link
            to="/about"
            className={`p-2 rounded-md transition-colors duration-300 ${
              darkMode ? 'text-white' : 'text-black'
            }`} // Text color changes based on darkMode
          >
            <div className={`text-${darkMode ? 'white' : 'DimGray'} text-lg font-semibold hover:text-Sunburst`}>
              About
            </div>
          </Link>
          <Link
            to="/services"
            className={`p-2 rounded-md transition-colors duration-300 ${
              darkMode ? 'text-white' : 'text-black'
            }`} // Text color changes based on darkMode
          >
            <div className={`text-${darkMode ? 'white' : 'DimGray'} text-lg font-semibold hover:text-Sunburst`}>
              Services
            </div>
          </Link>
          <Link
            to="/contact"
            className={`p-2 rounded-md transition-colors duration-300 ${
              darkMode ? 'text-white' : 'text-black'
            }`} // Text color changes based on darkMode
          >
            <div className={`text-${darkMode ? 'white' : 'DimGray'} text-lg font-semibold hover:text-Sunburst`}>
              Contact
            </div>
          </Link>
        </div>
        <button
          onClick={darkModeHandler}
          className={`text-${darkMode ? 'white' : 'black'} font-semibold hover:text-Sunburst`}
        >
          {darkMode ? <FontAwesomeIcon icon={faSun} /> : <FontAwesomeIcon icon={faMoon} />}
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
