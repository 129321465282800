import React, { useState, useEffect } from 'react';
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Landing from './Components/Landing/Landing';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Services from './Components/Services/Services';
import Contact from './Components/Contact/Contact';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

function App() {
  const [darkMode, setDarkMode] = useState(true); // Dark mode state
  const [showNavbar, setShowNavbar] = useState(true); // Control Navbar visibility

  // Function to toggle dark mode
  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div id="App">
      <Router basename="/">
        {/* Use a custom route component to conditionally render Navbar */}
        <ConditionalNavbar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />

        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route exact path="/home" element={<Home darkMode={darkMode} toggleDarkMode={toggleDarkMode} />} />
          <Route exact path="/about" element={<About darkMode={darkMode} toggleDarkMode={toggleDarkMode} />} />
          <Route exact path="/services" element={<Services darkMode={darkMode} toggleDarkMode={toggleDarkMode} />} />

          <Route exact path="/contact" element={<Contact darkMode={darkMode} toggleDarkMode={toggleDarkMode} />} />
        </Routes>
      </Router>
    </div>
  );
}

// A custom route component to conditionally render Navbar
function ConditionalNavbar({ darkMode, toggleDarkMode }) {
  const location = useLocation();

  // Render Navbar conditionally based on the route
  const shouldRenderNavbar = location.pathname !== '/';

  if (shouldRenderNavbar) {
    return <Navbar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />;
  }
  
  return null; // Don't render Navbar on landing page
}

export default App;
