import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

const Area69 = ({ onClose }) => {
  const [secretText, setSecretText] = useState('');

  const handleTextChange = (event) => {
    setSecretText(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Do something with the secret text
    console.log('Secret Text:', secretText);
    let dummy = secretText;
    if (dummy=="meow69"){
      console.log("approved");
    }else{
      console.log("nah :(");
    }
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>
          X
        </button>
        <form onSubmit={handleSubmit}>
          <input type="text" value={secretText} onChange={handleTextChange} placeholder="Passphrase?" />
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

const Landing = () => {
  
  const [showContent, setShowContent] = useState(false);
  const [sarcasmClickCount, setSarcasmClickCount] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const navigate = useNavigate();
  
  const letsGo = () => {
    setShowContent(true);
    setTimeout(() => {
      navigate('/home', { replace: true })
    }, 1000);
  };

  const handleSarcasmClick = () => {
    setSarcasmClickCount((prevCount) => prevCount + 1);
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    if (sarcasmClickCount === 3) {
      openPopup();
      setSarcasmClickCount(0);
    }
  }, [sarcasmClickCount]);

  return (
    <div
      className="bg-storm h-screen flex items-center justify-center"
      style={{ position: 'relative', overflow: 'hidden' }}
    >
      <div
        className={`max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 ${showContent ? 'opacity-0' : 'opacity-100'}`}
        style={{ transition: 'opacity 1s' }}
      >
        <div className="text-center">
          <h1 className="text-4xl text-Sunburst font-bold mb-4">Welcome to My Humble Abode</h1>

          <p className="text-xl text-gray-400">
            Stoicism,{' '}
            <span className="" onClick={handleSarcasmClick}>
              Sarcasm
            </span>
            , Chasm.
          </p>
        </div>
        <div className="mt-10 flex justify-center" onClick={letsGo}>
          <NavLink to="/home" className="bg-gray-500 hover:bg-Sunburst text-white font-bold py-3 px-6 rounded-lg">
            Let's Go!
          </NavLink>
        </div>
      </div>

      {isPopupOpen && <Area69 onClose={closePopup} />}
    </div>
  );
};

export default Landing;
