import React, { useState } from 'react';
import Navbar from '../Navbar/Navbar';
import Typical from 'react-typical'; // Import the react-typical library
import placeholderImage from '../../images/placeholder.png';
import placeholderImage2 from '../../images/freelancer.jpg';

const Home = ({darkMode}) => {
  const [expandedProject, setExpandedProject] = useState(null);

  const toggleProject = (index) => {
    if (expandedProject === index) {
      setExpandedProject(null);
    } else {
      setExpandedProject(index);
    }
  };

  return (
    <div>
      
      <div className={`${darkMode ? 'bg-Sunburst' : 'bg-black'} h-1 absolute left-0 right-0 top-100`}></div>

      <div className={`${darkMode ? 'bg-TheArker' : 'bg-lightNavbar'} h-screen flex items-center justify-center `}>
        <div className={`max-w-xl text-center ${darkMode ? 'text-white' : 'text-DimGray'}`}>
          {/* Use the Typical component for the dynamic name animation */}
          <h1 className="text-4xl font-bold mb-4">
            Hello, I'm{' '}
            <span className='inline-block w-[120px]'>
            <Typical className="text-Sunburst"
              steps={['अग्रिम', 1500, 'Agrim', 1500]} // Display Devanagari name for 1500ms, then transition to English name for 1500ms
              loop={Infinity}
              wrapper="span"
            />
            </span>
          </h1>
          <p className="text-lg mb-8">
            A knowledge hungry consciousness looking to unravel the workings of the universe one component at a time ;)
          </p>
          <div className="mt-8">
            <h2 className="text-2xl font-semibold mb-4">Remarkable Projects</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {projectsData.map((project, index) => (
                <div key={index} className="relative">
                  <div
                    className={` p-4 h-24 cursor-pointer transition-height`}
                    onClick={() => toggleProject(index)}
                  >
                    <img
                      src={project.image}
                      alt={`Project ${index + 1}`}
                      className="w-24 h-24 rounded-full inline-block mb-4 mx-auto"
                    />
                    <p className="text-lg font-semibold mb-2 text-gray-600">
                      {project.title}
                    </p>
                  </div>
                  {expandedProject === index && (
                    <div className="absolute bg-white p-4 rounded-lg shadow-md top-full left-0 transform translate-y-2 z-10">
                      <h3 className="text-lg font-semibold mb-2 text-gray-600">
                        {project.title}
                      </h3>
                      <p className="text-gray-600">{project.description}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Horizontal line below the navigation bar */}
    </div>
  );
};

export default Home;

const projectsData = [
  {
    title: 'SimpliciT',
    description:
      'A digital time logging solution intended to replace and ease up the process of logging hours in a workplace.',
    image: placeholderImage,
  },
  {
    title: 'WebSpinners',
    description:
      'An online marketplace for pre-loved items that gives the owner full control of the listing, pricing and uptime.',
    image: placeholderImage2,
  },
  // Add more project data objects
];
