import React, { useState } from 'react';

const Contact = ({ darkMode, toggleDarkMode }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      const response = await fetch('http://localhost:5000/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, message }),
      });
  
      if (response.ok) {
        setSubmitted(true);
        // Reset the form fields after successful submission
        setName('');
        setEmail('');
        setMessage('');
      } else {
        // Handle the case where the server returns an error
        console.error('Failed to submit the form');
      }
    } catch (error) {
      console.error('Error sending form data:', error);
    }
  };
  

  return (
    <div>
    <div className={`${darkMode ? 'bg-Sunburst' : 'bg-black'} h-1 absolute left-0 right-0 top-100`}></div>
    <div className={`${darkMode ? 'bg-TheArker' : 'bg-lightNavbar'} mx-auto py-8 px-4 md:px-8 h-screen overflow-hidden ${darkMode ? 'text-white' : 'text-black'}`}>
            

      <h2 className="text-2xl font-semibold mb-4">Contact Me</h2>
      {submitted ? (
        <p className={`mb-8 ${darkMode ? 'text-Sunburst' : 'text-DimGray'}`}>
          Thank you for reaching out! I'll get back to you soon.
        </p>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block mb-1 font-semibold">
              Name<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={handleNameChange}
              className="w-full p-2 border rounded text-black"

              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block mb-1 font-semibold">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              className="w-full p-2 border rounded text-black"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block mb-1 font-semibold">
              Message<span className="text-red-500">*</span>
            </label>
            <textarea
              id="message"
              value={message}
              onChange={handleMessageChange}
              className="w-full p-2 border rounded text-black"
              rows="5"
              required
            />
          </div>
          <button
            type="submit"
            className={`bg-Sunburst hover:bg-yellow-400 text-white font-bold py-2 px-4 rounded ${darkMode ? 'hover:bg-yellow-300' : 'hover:bg-yellow-500'}`}
          >
            Submit
          </button>
        </form>
      )}
    </div>
    </div>
  );
};

export default Contact;
