import React from 'react';

function Services({ darkMode }) {
  return (
    <div>
      <div className={`${darkMode ? 'bg-TheArker' : 'bg-lightNavbar'} h-1 absolute left-0 right-0 top-100`}></div>
      <div className="bg-Sunburst h-1 absolute left-0 right-0 top-100"></div>

      <div className={`${darkMode ? 'bg-TheArker' : 'bg-lightNavbar'} mx-auto py-8 px-4 md:px-8 h-screen overflow-hidden ${darkMode ? 'text-white' : 'text-black'}`}>
        <h1 className={`text-4xl font-bold ${darkMode ? 'text-Sunburst' : 'text-black'}`}>Our Services</h1>
        {/* Add your service content here */}
      </div>
    </div>
  );
}

export default Services;
